var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper auth-v1 px-2"
  }, [_c('div', {
    staticClass: "auth-inner py-2"
  }, [_c('b-card', {
    staticClass: "mb-0"
  }, [_c('b-link', {
    staticClass: "brand-logo d-flex",
    attrs: {
      "to": "/"
    }
  }, [_c('b-img', {
    staticStyle: {
      "width": "20%"
    },
    attrs: {
      "src": require('@/assets/images/logo/logo.png'),
      "alt": "logo"
    }
  })], 1), _c('b-card-title', {
    staticClass: "mb-1"
  }, [_vm._v(" Reset Password")]), _c('validation-observer', {
    ref: "resetPasswordValidation"
  }, [_c('b-form', {
    staticClass: "auth-reset-password-form mt-2",
    attrs: {
      "method": "POST",
      "autocomplete": "off"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "login-email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "email",
      "rules": "required|email",
      "vid": "email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "login-email",
            "state": errors.length > 0 ? false : null,
            "placeholder": "john@example.com",
            "autocomplete": "off"
          },
          model: {
            value: _vm.email,
            callback: function callback($$v) {
              _vm.email = $$v;
            },
            expression: "email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "New Password",
      "label-for": "reset-password-new"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "password",
      "vid": "password",
      "rules": "required|min:6"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge",
          attrs: {
            "id": "reset-password-new",
            "type": _vm.password1FieldType,
            "state": errors.length > 0 ? false : null,
            "name": "reset-password-new",
            "placeholder": "············",
            "autocomplete": "off"
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.password1ToggleIcon
          },
          on: {
            "click": _vm.togglePassword1Visibility
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label-for": "reset-password-confirm",
      "label": "Confirm Password"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Confirm Password",
      "rules": "required|confirmed:password"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge",
          attrs: {
            "id": "reset-password-confirm",
            "type": _vm.password2FieldType,
            "state": errors.length > 0 ? false : null,
            "name": "reset-password-confirm",
            "placeholder": "············",
            "autocomplete": "off"
          },
          model: {
            value: _vm.passwordConfirmation,
            callback: function callback($$v) {
              _vm.passwordConfirmation = $$v;
            },
            expression: "passwordConfirmation"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.password2ToggleIcon
          },
          on: {
            "click": _vm.togglePassword2Visibility
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isLoading ? [_c('b-button', {
    attrs: {
      "variant": "secondary",
      "block": "",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    attrs: {
      "block": "",
      "type": "submit",
      "variant": "secondary"
    }
  }, [_vm._v(" Set New Password ")])]], 2)], 1), _c('p', {
    staticClass: "text-center mt-2"
  }, [_c('b-link', {
    attrs: {
      "to": {
        name: 'login'
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ChevronLeftIcon"
    }
  }), _vm._v(" Back to login ")], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }